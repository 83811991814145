@import "../../mixins.scss";

.transaction-history-container{
    display: flex;
    justify-content: center;
    width: 100%;
    .transaction-history {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(
          127deg,
          hsla(0, 0%, 100%, 0.16) 17.35%,
          hsla(0, 0%, 85%, 0.04) 76.2%
        );
        border-radius: 15px;
        padding: 2rem;
        margin-top: 20px;
      
        &__header {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
      
        &__table-wrap {
          width: 100%;
          overflow-x: auto;
      
          table {
            width: 100%;
            border-collapse: collapse;
      
            thead {
              background: #2e2c2c;
      
              tr {
                th {
                  padding: 10px;
                  text-align: left;
                  font-weight: 600;
                }
              }
            }
      
            tbody {
              .transaction-history__table-body {
                tr {
                  td {
                    // padding: 10px;
                    border-top: 1px solid #e0e0e0;
      
                    &.transaction-history__deposit {
                      color: green;
                    }
      
                    &.transaction-history__withdrawal {
                      color: red;
                    }
                  }
      
                  &.transaction-history__no-history {
                    text-align: center;
      
                    p {
                      margin: 0;
                      color: #999;
                    }
                  }
      
                  .transaction-history__currency-wrap {
                    display: flex;
                    align-items: center;
                    gap: 8px;
      
                    img {
                      width: 24px;
                      height: 24px;
                    }
                  }
                }
              }
            }
          }
        }
        &__deposit {
            color: green;
          }
        
          &__withdrawal {
            color: red;
          }
        
          &__pagination {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        
            .transaction-history__page-btn {
              margin: 0 5px;
              padding: 0.5rem 1rem;
              background-color: #f3f3f3;
              border: none;
              cursor: pointer;
        
              &.active {
                background-color: #007bff;
                color: white;
              }
        
              &:hover {
                background-color: #e0e0e0;
              }
        
              &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
              }
            }
          }
      }
}

  
  
.table-wrap {
    margin-top: 20px;
    max-height: 200px; 
    overflow-y: auto;
    h3 {
      padding-left: 30px;
      text-align: center;
    }
  }
  .table-wrap th {
    background-color: rgb(29 35 43 ); 
    position: sticky;
    top: 0; 
    z-index: 1; 
  }
  .no-history{
    text-align: center;
    height: 100px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    padding: 0px 30px;
    background-color: rgba(229, 229, 229, 0.1);
  }
  
  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid;
    border-color: rgba(229, 229, 229, 0.1);
    // padding-left: 26px;
    // padding-right: 26px;
    @include respond(phone) {
      padding-left: 7px;
      padding-right: 7px;
    }
  }