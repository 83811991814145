.create-merchant-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    
    form {
      width: 100%;
      
      .form-group {
          margin-bottom: 15px;
          width: 100%;
        
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
          color: #fff;
        }
        
        input {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
        }
      }
      
      button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s;
        box-sizing: border-box;
        
        &:hover {
          background-color: #0056b3;
        }
        
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }
    
    .message {
      color: green;
      text-align: center;
      font-size: 16px;
      margin-bottom: 15px;
    }
    
    .error {
      color: red;
      text-align: center;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  
  .success-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }
  
  .success-message h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #388e3c;
  }
  
  .success-message p {
    font-size: 1rem;
    margin-bottom: 0;
  }
  