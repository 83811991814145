.bitcoin-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    background-color: #121212;
    color: #ffffff;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h1 {
    font-size: 2rem;
  }
  
  .header-icon img {
    width: 40px;
    height: 40px;
  }
  
  .btc-market-value {
    margin-top: 20px;
  }

  .time-range-buttons{
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  .btc-market-value h2 {
    font-size: 2rem;
  }
  
  .change {
    color: #00ff00;
  }
  
  .btc-balance {
    margin-top: 20px;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .actions button {
    width: 48%;
    padding: 15px;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .buy {
    background-color: #0000ff;
    color: #ffffff;
  }
  
  .sell {
    background-color: #ff0000;
    color: #ffffff;
  }
  