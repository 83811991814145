*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import "../../mixins.scss";
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
ul {
  list-style: none;
}

ol {
  list-style:none; 
}
.navbar {
    background-color: #02080f;
    display: flex;
    align-items: center;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 999;
    padding-left: 200px;
    padding-right: 200px;
    @include respond(phone) {
      padding-left: 10px;
      padding-right: 10px;
      height: 70px;
    }
  }
  
  .nav-content{
    // padding: 50px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo{
    display: flex;
    align-items: center;
    gap: 7px;
    img {
      width: 40px;
      @include respond(phone) {
        width: 30px;
      }
    }
    .project-name{
      display: flex;
      flex-direction: column;
      p:first-child{
        font-size: 30px;
        font-weight: bold;
        @include respond(phone) {
          font-size: 25px;
        }
      }
      p:nth-child(2) {
        font-size: 16px;
        padding: 0px;

        
      }
    }
  }

  .user-top-info{
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    // background-color: #091e32;
    background-color: rgba(21, 31, 43, 0.4);
    padding: 5px 10px;
    .user-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      @include respond(phone) {
        width: 32px;
      }
    }
    .left-arrow-icon{
      @include respond(phone) {
        display: none;
      }
    }
    span{
      font-size: 20px;
      @include respond(phone) {
        display: none;
      }
    }
  }


  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 10px;
    padding: 12px 30px;
    border: 1px solid hsla(0, 0%, 100%, .15);
    border-radius: 40px;
  }
 
  .nav-links li {
    font-size: 18px;
    margin-right: 20px;
  }
  
  .nav-links li a {
    color: #fff;
    text-decoration: none;
    padding: 5px 10px;
  }
  
  .nav-links li a:hover {
    background-color: #555;
    border-radius: 5px;
  }
  .nav-side-link{
    /* padding: 100px 30px 0px 15px; */
    /* text-decoration: none; */
    list-style: none;
  }
  .nav-side-link li{
    margin-bottom: 10px;
    font-size: 16px;
  }

  .login-toggle-wrap{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .login-btn{
    padding: 12px 30px;
    border-radius:.55rem;
    background-color: rgb(28, 100, 224);
    color: #fff;
  }
  .toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #093637;
    color: #fff;
  }
  .close-icon{
    transform: rotate(1turn);
    /* display: none; */
  }
  .side-nav {
    z-index: 10;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    /* transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms; */
    color: white;
    /* background: #0b1126; */
    background-color: #0000007f;
    /* background-color: red; */
    /* background-color: rgb(2 8 15 ); */
    backdrop-filter: blur(10px);
    
  }
  .transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    // transition-duration: .15s;
    transition-duration: .3s;
  }
  .side-nav-else {
    position: fixed;
    left: -100%;
  }
  .side-nav-wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 25px;
    width: 70%;
    height: 100%;
    background-color: rgb(2 8 15 );
    position: absolute;
    right: 0px;
    top: 0px;
    /* position: fixed; */
    /* /* right: 0px; */
    /* top: 0px; */


    
  }
  .nav-side-link{
    display: flex;
    flex-direction: column;

  }
  .menu-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  .side-nav-button{
    padding-top: 20px;
    border-top: 1px solid;
    border-color: rgba(229, 229, 229, 0.1);
    display: flex;
     
    
  }
  .side-nav-deposit-btn-wrap{
    display: flex;
    gap: 5px;
    width: 100%;
    button{
      font-size: 16px;
      color: #fff;
      background-color: blue;
      border-radius: 7px;
      padding: 3px 10px;
      width: 190px;


    }
  }


  @media (min-width: 320px) and (max-width:480px){
    .nav-content{
      padding: 50px 30px ;
      padding-left: 0px;
      @include respond(phone) {
        padding: 0px ;
      }
    }
    .nav-links {
      display: none;
    }
    .login-btn{
      display: none;
    }
  }
  @media (min-width: 481px) and (max-width:1024px){
    
  }
  @media (min-width: 1024px) {
    .toggle{
      display: none;
    }
    
  }
  

  ////KESOLINK CAN REMOVE THIS, I DIDNT ALTER ANY OTHER STYLE I JUST ADDED THIS TO GET WHAT I WANTED
  
  
  .avatar-sect{
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .nav-menu-wrap{
    cursor: pointer;
    font-size: 25px;
  }

  .menu-cont{
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .avatar-container {
    position: relative;
    // display: inline-block;
    display: flex;
    align-items: center;
    // cursor: pointer;
  
    
  
    .dropdown-menu {
      position: absolute;
      right: 0;
      top: 0;
      width: 300px;
      height: 100vh;
      background-color: #091e32;
      z-index: 100;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      overflow-y: auto;
    }
  
    .dropdown-menu ul {
      list-style: none;
      padding: 0;
    }
  
    .dropdown-menu li {
      padding: 10px 0;
      border-bottom: 1px solid #ffffff3b;
    }
  
    .dropdown-menu li a {
      color: #fff;
      text-decoration: none;
    }
  
    .dropdown-menu li a:hover {
      color: aquamarine;
    }
  
    .close-dropdown-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      font-size: 24px;
    }
  }
  