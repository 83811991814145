@import "../mixins.scss";
.register-container {
  display: flex;
  width: 100%;
  margin: 0px 100px;
  @include respond(phone) {
    margin: 0px;
  }

  .reg-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
      width: 100%;
    }
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .reg-form-group {
    width: 70%;
    margin-bottom: 10px;
    @include respond(phone) {
      width: 95%;
    }
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: rgb(251 251 251);
    @include respond(phone) {
      font-size: 12.3px;
    }
  }
  .reg-form-group input {
    border: none;
    outline: none;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.07);

    &:focus {
      background-color: hsla(0, 0%, 100%, 0.07);
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important; 
      -webkit-text-fill-color: rgb(251, 251, 251) !important; 
    }

    &:-moz-autofill {
      box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important;
    }

    &:-ms-autofill {
      box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important;
    }

    &:-o-autofill {
      box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important;
    }

    &:focus-within {
      border: .1rem solid #2764ff !important;
      box-shadow: 0 0 0 3px rgba(0, 105, 255, .16);
    }


  }

  .passwd{
    position: relative;
  }

  .eye-icon{
    position: absolute;
    right: 20px;
    top: 35px;
    color: rgb(28 100 224);
    cursor: pointer;
  }
  .btn-register{
    padding: 12px 60px;
    margin-top: 10px;
    border-radius: .55rem;
    background-color: rgb(28 100 224);
    width: 70%;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  
  }
  .reg-link{
    display: flex; 
    justify-content: flex-end;
    width: 70%;
    margin-top: 7px;
    @include respond(phone) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    span{
      font-size: 14px;
      .reg-link-reg{
        font-weight: 700;
      }
    }
  }

  .reg-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
}
