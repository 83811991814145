@import '../../mixins.scss';
.listed-tokens-container {
  
  .listed-tokens-title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      @include respond(phone) {
        font-size: 18px;
      }
      
  }

  .listed-token-search-input {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
  }

  .listed-token-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      max-height: 500px; 
      overflow-y: auto;
      padding-bottom: 50px;
      @include respond(phone) {
        gap: 10px;
      }
      
  }

  .listed-token-container {
      flex: 1 1 calc(25% - 20px);  
      box-sizing: border-box;
      max-height: 300px;
      overflow-y: hidden;
      padding-right: 10px; 
      @include respond(phone) {
        flex: 1 1 calc(100% - 20px);  
      }
      @include respond(tab) {
        flex: 1 1 calc(50% - 20px);  
      }

      .token-box {
          background-color: rgba(21, 31, 43, .4);
          border-radius: 8px;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          @include respond(phone) {
            padding: 10px;
          }
          
          
          &:hover {
              transform: translateY(-5px);
          }

          .token-content-wrap {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;

              &:last-child {
                  margin-bottom: 0;
              }

              .token-image {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                  border-radius: 50%;
                  @include respond(phone) {
                    width: 30px;
                    height: 30px;
                  }
              }

              .token-name-link {
                  font-weight: bold;
                  text-decoration: none;
                  color: #fff;
                  font-size: 18px;
                  @include respond(phone) {
                    font-size: 16px;
                  }
              }

              .buy-button {
                  padding: 8px 16px;
                  background-color: #28a745;
                  color: #fff;
                  border: none;
                  border-radius: 12px;
                  cursor: pointer;
                  font-size: 12px;
                  @include respond(phone) {
                    padding: 6px 16px;
                  }

                  &:hover {
                      background-color: #218838;
                  }
              }

              .token-price {
                  font-size: 20px;
                  font-weight: bold;
                  @include respond(phone) {
                    font-size: 16px;
                  }
              }

              .token-symbol {
                  font-size: 16px;
                  font-weight: 600;
                  text-transform: uppercase;
              }

              .token-change {
                  font-size: 14px;
                  font-weight: bold;

                  &.positive-change {
                      color: #28a745;
                  }

                  &.negative-change {
                      color: #dc3545;
                  }
              }
          }
      }
  }
}

.positive-change {
  color: #28a745;
}

.negative-change {
  color: #dc3545;
}



@media (max-width: 768px) {
  .listed-token-container {
      flex: 1 1 calc(50% - 20px);  // 2 boxes per row on tablet
  }
}

@media (max-width: 480px) {
  .listed-token-container {
      flex: 1 1 100%;  // 1 box per row on mobile
  }
}

