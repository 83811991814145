.confirm-withdrawal-container {
    width: 100%;
    height: 100%;
    padding: 50px;
}

.filter-buttons {
  display: flex;
  justify-content: space-around;
}

.filter-buttons button {
  margin-right: 10px;
  width: 200px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
}

.filter-buttons button:hover {
  background-color: #0056b3;
}

.withdrawal-c {
    padding: 50px;
    display: flex;
}

.confirm-withdrawal-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.withdrawal-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style-type: none;
    padding: 0;
}

.withdrawal-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.confirmed-status {
    color: green;
}

.pending-status {
    color: goldenrod;
}

.canceled-status {
    color: red;
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.confirm-btn, .cancel-btn {
    width: 150px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
}

.cancel-btn {
    background-color: rgb(169, 92, 92);
}

.confirm-btn:hover {
    background-color: #0056b3;
}

.cancel-btn:hover {
    background-color: rgb(145, 41, 41);
}

.withdrawal-item.labels {
    font-weight: bold;
}

.withdrawal-item.labels p {
    flex: 1;
    text-align: center;
}
