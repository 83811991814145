@import "../mixins.scss";

.login-container{
  display: flex;
  width: 100%;

.login-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond(phone) {
    display: none;
  }
  img{
    width: 100%;
  }
}

.login-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond(phone) {
    width: 100%;
  }

}
.sign-in-wrap{
  display: flex;
  flex-direction: column;
  width: 50%;
  @include respond(phone) {
    width: 90%;
  }
}
.header-text{
  font-size: 40px;
  font-weight: 600;
  @include respond(phone) {
    font-size: 32px;
  }
}
.caution-wrap{
  padding: 4px 20px;
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: rgb(22 26 32);
  padding-left: .875rem;
  padding-right: .875rem;
  border-radius: .5rem;
  @include respond(phone) {
    font-size: 12px;
  }
  img{
    width: 30px;
  }
  .securelink{
    color: rgb(88, 189, 125);
  }
}
.login-form-group {
  margin-top: 15px;
  padding: 10px 20px;
  border-radius: 0.75rem;
  border: 1px solid rgb(43, 46, 52);
  background-color: rgb(22, 26, 32);
  width: 100%;

  input {
    background-color: inherit;
    border: none;
    outline: none;
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgb(251, 251, 251);
    width: 100%;
    
    @include respond(phone) {
      border: none;
    }
    &:focus {
      color: white;
      -webkit-text-fill-color: rgb(251, 251, 251) !important; 
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important; 
      -webkit-text-fill-color: rgb(251, 251, 251) !important; 
    }

    &:-moz-autofill {
      box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important;
    }

    &:-ms-autofill {
      box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important;
    }

    &:-o-autofill {
      box-shadow: 0 0 0px 1000px rgb(22, 26, 32) inset;
      color: rgb(251, 251, 251) !important;
    }
  }

  &:focus-within {
    border: .1rem solid #2764ff !important;
    box-shadow: 0 0 0 3px rgba(0, 105, 255, .16);
  }
}

.passwd{
  position: relative;
}

.eye-icon{
  position: absolute;
  right: 20px;
  top: 15px;
  color: rgb(28 100 224);
  cursor: pointer;
}

.Forget-Password-wrap{
  display: flex;
  justify-content: flex-end ;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  span{
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    @include respond(phone) {
      font-size: 12.5px;
    }
  }
}
.btn-login{
  padding: 12px 60px;
  margin-top: 10px;
  border-radius: .55rem;
  background-color: rgb(28 100 224);
  width: 100%;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

}


.or-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.or-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  width: 200px;
  
}

.or-divider::before,
.or-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}

.or-divider span {
  padding: 0 10px;
  font-size: 14px;
  color: #666;
}

.google-wrap{
  padding: 5px 20px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 0.75rem;
  border: 1px solid rgb(43, 46, 52);
  background-image: linear-gradient(170deg, #2b2f34, #0b1016);
  .google-svg-wrap{
    width: 30px;
  }
  span{
    font-size: 16px;
    @include respond(phone) {
      font-size: 13px;
    }
  }
  .coming-soon {
    font-size: .478rem; 
    padding: 2px 4px;
    border-radius: 5px;
    position: relative;
    top: -5px; 
    color: rgb(89 121 158 ); 
    border: solid 1px rgb(89 121 158 ) ;
  }

}
.reg-link{
  margin-top: 7px;
  span{
    font-size: 14px;
  }
}

}