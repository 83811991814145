@import "../mixins.scss";

.dashboard-container {
  background-image: url(../assets/black-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  padding-left: 200px;
  padding-right: 200px;
  position: fixed;
  overflow-y: scroll;

  @include respond(xs-phone) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @include respond(sm-phone) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include respond(phone) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include respond(tab) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include respond(desktop) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include respond(lg-desktop) {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.greetings-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.welcome-greetings {
  display: inline-block;
  font-size: 2rem;
  animation: slideIn 20s linear infinite;
}


.dashboard-content {
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
}

.dashboard-balance {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  border-radius: 12px;
  @include respond(phone) {
    flex-direction: column;
  }
}

.dashboard-balance-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  gap: 7px;
  background: linear-gradient(
    127deg,
    hsla(0, 62%, 81%, 0.16) 17.35%,
    hsla(0, 0%, 85%, 0.04) 76.2%
  );
  width: 50%;
  border-radius: 12px;
  @include respond(phone) {
    width: 100%;
  }
}

.dashboard-balance-amount {
  display: flex;
  align-items: center;
  gap: 7px;
  h2 {
    @include respond(phone) {
      font-size: 19px;
    }
  }
}

.dashboard-balance-right {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  gap: 15px;
  background: linear-gradient(
    127deg,
    hsla(0, 62%, 81%, 0.16) 17.35%,
    hsla(0, 0%, 85%, 0.04) 76.2%
  );
  width: 50%;
  border-radius: 12px;
  justify-content: space-between;
  @include respond(phone) {
    width: 100%;
  }
}

.dashboard-currency-select {
  select {
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    background-color: transparent;
    color: white;
  }
}

.dashboard-funding {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-funding button {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.dashboard-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 5px;
  color: white;
  font-size: 14px;
}

.dashboard-footer p {
  font-size: 12px;
}

.dashboard-action-buttons {
  display: flex;
  justify-content: space-between;
  // background: black;
  padding: 10px;
  border-radius: 10px;
}




// ****************************************new********************************
.dashboard-content-wrap{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  
  margin-top: 10px;
  margin-bottom: 20px;
  
}
.max-height-more{
  max-height: 280px;
}
.max-height-less{
  max-height: 280px;
}
.dashboard-main{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  width: 60%;
  height: 100%;
  padding: 30px;
  border-radius: 12px; 
  // background: linear-gradient(
  //   127deg,
  //   hsla(0, 0%, 100%, 0.16) 17.35%,
  //   hsla(0, 0%, 85%, 0.04) 76.2%
  // );
  @include respond(phone) {
    width: 100%;
    padding: 10px;
    gap: 5px;
  }
  @include respond(tab) {
    width: 100%;
    padding: 10px;
    gap: 5px;
  }
}
.bal-top-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  @include respond(phone) {
    padding-top: 20px;
  }
}
.total-fait-wrap{
  display: flex;
  flex-direction: column;
  .bal-show-wrap{
    font-size: 30px;
    @include respond(phone) {
      font-size: 20px;
    }
    span{
      font-size: 30px;
      @include respond(phone) {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }
}

.bal-text-select-wrap{
  display: flex;
  align-items: center;
  gap: 10px;
  @include respond(phone) {
    gap: 4px;
  }
  P{
    font-size: 16px;
    @include respond(phone) {
      font-size: 12px;
    }
  }
  .change-currency{
    width: 60px;
    border-radius: 30px;
    font-size: 12px;
  }
  .currencySelect{
    background-color: inherit;
  }
}

.dashboard-currency-symbol{
  font-size: 30px;
  font-weight: 600;
  .select-currencyt{
    width: 40px;
    border-radius: 30px;
  }
  // .currencySelect{
  //   background-color: inherit;
  // }
}


// .change-currency{
//   width: 60px;
//   border-radius: 30px;
// }
// .currencySelect{
//   background-color: inherit;
// }

.bal-action-wrap{
  display: flex;
  flex-direction: column;
  gap: 5px;
 
}
.show-balance{
  // margin-left: auto;
  align-self: flex-end;
  margin-right: 20px;
  cursor: pointer;
}

.bal-action-main-wrap{
  display: flex;
  align-items: center;
  gap: 20px;
}
.bal-deposit-wrap{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 8px;
  background: var(--light--1);
}
.bal-withdraw-wrap{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 8px;
  background: var(--light--1);
}



.show-more-wrap{
  display: flex;
  justify-content: right;
  cursor: pointer;
  // margin-bottom: 20px;
}
.margin-bottom-large {
  margin-bottom: 20px;
  @include respond(phone) {
    margin-bottom: 10px;
  }
}

.margin-bottom-small {
  margin-bottom: 5px;
}

.bal-btn-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px; 
  margin-right: 20px;

  @include respond(phone) {
    grid-template-columns: repeat(4, 1fr); 
    margin-right: 0px;
  }
}

.bal-btn {
  display: flex;
  flex-direction: column;
  padding: 6px 10px;
  border-radius: 12px;
  background: rgb(26, 39, 54);
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  @include respond(phone) {
    padding: 6px 1px;
    background: inherit;
  }

  span {
    @include respond(phone) {
      font-size: 12px;
      text-align: center;
    }
  }
}
.display-none {
  display: none;
}

.display-block {
  display: flex;
}

.bal-btn-less {
  flex-direction: column;
  padding: 6px 10px;
  border-radius: 12px;
  background: rgb(26, 39, 54);
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  
  .bal-icon-wrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  }

  @include respond(phone) {
    padding: 6px 1px;
    background: inherit;
  }

  span {
    @include respond(phone) {
      font-size: 12px;
      text-align: center;
    }
  }
}



.bal-icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}



.dashboard-asset{
  width: 40%;
  background-color: aqua;
}



// ***********************Asset css start************
.portfolio-reward-wraper {
  display: flex;
  gap: 20px;
  width: 40%;
  height: 100%;
  @include respond(phone) {
    display: none;
    flex-direction: column;
    margin-top: 20px;
  }
  @include respond(tab) {
    display: none;
    // flex-direction: column;
    // margin-top: 20px;
  }
}
.card-wrap {
  width: 100%;
  border-radius: 15px;
  padding: 2rem;
  // background: linear-gradient(
  //   127deg,
  //   hsla(0, 0%, 100%, 0.16) 17.35%,
  //   hsla(0, 0%, 85%, 0.04) 76.2%
  // );
  @include respond(phone) {
    padding: 15px;
  }
}  

.border-transparent {
  position: relative;
}
.border-transparent:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(127deg,hsla(0,0%,80%,.2),hsla(0,0%,80%,0) 100%);
  border-radius: inherit;
  padding: 1px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude
}
.card-title-wrap {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 10px;
}

.card-icon {
  padding: 9px;
  border-radius: 5px;
  background: linear-gradient(
    127deg,
    hsla(0, 62%, 81%, 0.16) 17.35%,
    hsla(0, 0%, 85%, 0.04) 76.2%
  );
}

.card-bal {
  padding: 10px 0px;
  h2{
    @include respond(phone) {
      font-size: 18px;
    }
  }
}
.card-reward-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  @include respond(phone) {
    gap: 5px;
  }
}

.card-component-1 {
  display: flex;
  align-items: center;
  width: 100%;
}

.card-component-1{
  display: flex;
  justify-content: space-between;

  .coin-wrap{
    display: flex;
    align-items: center;
    padding: 15px 0px;
    gap: 5px;
    img{
      width: 34px;
    }
  }
}
.border-line{
  border-top: 1px solid;
  border-color: rgba(229, 229, 229, 0.1);
  gap: 20px;
  @include respond(phone) {
    gap: 10px;
  }
}

.card-reward-wrap {
  padding-top: 7px;
  padding-bottom: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  @include respond(phone) {
    gap: 5px;
  }
  img{
    width: 30px;
  }
}

.reward-value {
  h3{
    font-size: 18px;
  }
  p {
    font-size: 16px;
    color: #fff;
    @include respond(phone) {
      font-size: 13px;
    }
}
}

.btn-deposit-withdrwal{
  padding-top: 7px;
  padding-bottom: 3px;
  display: flex;
  justify-content: center;
  gap: 15px;
  @include respond(phone) {
    gap: 10px;
  }
  button{
  padding: 10px 20px;
  border-radius: 7px;
  background-color: rgb(29 35 43 );
  color: #fff;
  @include respond(phone) {
    padding: 7px 12px;
    font-size: 12px;
  }
  }
}










// ***********************big-container-deisplay******************

.bal-big-container-wrap {
  background-color: rgba(21, 31, 43, 0.4);
  border-radius: 20px;
  color: #fff;
}

.tabs-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: rgb(124, 124, 124);
  border-radius: 12px;
  padding-top: 10px;
}

.left-tabs-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  margin: 12px 12px;
  border-radius: 0.5rem;
  background: rgb(29, 35, 43);
  @include respond(phone) {
    width: 55%;
  }
}

.coin-price,
.transaction,
.staked-asset{
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(124 124 124);
  border-radius: 4px;
  margin-right: 10px;
  @include respond(phone) {
    font-size: 12px;
    padding: 10px 0px;
    margin-right: 5px;
  }
  


  &.activetab {
    background: linear-gradient(127deg, #41454a 17.35%, #242a31 76.2%);
    color: #fff;
  }

  &:hover {
    color: #dcdcdc;
  }

  &:last-child {
    margin-right: 0;
  }
}

.right-tabs-wrap {
  display: flex;
  align-items: center;
  background: rgb(29, 35, 43);
  padding: 6px 9px;
  border-radius: .5rem;
  gap: 6px;
  width: fit-content;
  height: fit-content;
  margin: 12px 12px;

  .search-bal-input {
    font-size: 14px;
    background-color: inherit;
    color: #fff;
    width: fit-content;
    height: 25px;
    display: none;
    @include respond(phone) {
      width: 90px;
    }
  }
  .search-coin-price{
    font-size: 14px;
    background-color: inherit;
    color: #fff;
    width: fit-content;
    height: 25px;
    display: none;
    @include respond(phone) {
      width: 90px;
    }
  }

  // svg {
  //   color: #ccc;
  //   font-size: 24px;
  // }
}

.display-area {
  .coin-price-component,
  .transction-component,
  .stake-component {
    display: none;
  }

  .coin-price-component.activetab,
  .transction-component.activetab,
  .stake-component.activetab {
    display: block;
  }
}
.search-bal-input.activetab,
  .search-coin-price.activetab {
    display: block;
  }


//////////////quotes
  .dashboard-quotes-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // background-color: #402222;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
  }

  .quote-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .dashboard-quotes-section h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .dashboard-quotes-section p {
    font-size: 1.25rem;
  }
  