.merchant-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 28px;
      color: white;
    }
  
    ul {
      list-style-type: none;
      padding: 0;
  
      li.merchant-item {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
  
        .merchant-info {
          flex: 1;
  
          p {
            margin: 0;
            font-size: 16px;
            color: #fff;
  
            strong {
              color: #fff;
            }
          }
  
          .merchant-actions {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            gap: 10px;
  
            button {
              padding: 10px 15px;
              border: none;
              border-radius: 4px;
              font-size: 14px;
              cursor: pointer;
              transition: background-color 0.3s ease;
  
              &.approve {
                background-color: #4caf50;
                color: white;
  
                &:hover {
                  background-color: #45a045;
                }
              }
  
              &.disapprove {
                background-color: #f44336;
                color: white;
  
                &:hover {
                  background-color: #e53935;
                }
              }
  
              &.update {
                background-color: #2196f3;
                color: white;
  
                &:hover {
                  background-color: #1e88e5;
                }
              }
  
              &.delete {
                background-color: #9e9e9e;
                color: white;
  
                &:hover {
                  background-color: #757575;
                }
              }
            }
          }
        }
  
        .merchant-images {
          display: flex;
          flex-direction: column;
          gap: 10px;
  
          img {
            width: 100px;
            height: 100px;
            border-radius: 8px;
          }
        }
      }
    }
  }
  
  .merchant-image-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
    .m-image {
      width: 500px;
      height: 600px;
    }
    
    .m-images img {
      cursor: pointer;
    }
  }
  
  