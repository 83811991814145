.feature-text-wrap{
    text-align: center;
    margin-top: 70px;
    /* overflow-x: scroll; */
}
.feature-text-wrap h1{
    font-size: 55px;
    margin-bottom: 20px;
}
.fea-h1{
    font-size: 55px;
    margin-bottom: 45px;
    letter-spacing: -2.6px;
    background: linear-gradient(180deg, #fff 0, #93b2c8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.feature-wrap{
    margin: 60px 170px;
    display: flex;
    gap: 15px;
    
}
.box{
    padding: 40px;
    display: flex;
    /* flex-direction: column-reverse; */
    align-items: center;
    gap: 60px;
    background-color: #0f101e;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    margin-bottom: 30px;
    height: 280px;
    
}
.box-text{
    margin-top: 40px;
    width: 50%;
}
.box-text h2{
    font-size: 29px;
    margin-bottom: 15px;
}
.box-text h2:hover{
    color: #44a08d;
}
.box-text p{
    font-size: 18px;
    line-height: 1.62;
    color: #757575;
}
.feature-img {
    display: flex;
    justify-content: end;
    width: 50%;
}

.feature-img img{
    width: 60%;
}

@media (min-width: 320px) and (max-width:480px){
    .feature-text-wrap h1{
        font-size: 25px;
        margin-bottom: 20px;
    }
    .feature-wrap{
        margin: 60px 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        
    }
    .box{
      padding: 20px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 20px;
      background-color: #0f101e;
      margin-left: 0px;
      margin-right: 0px;
      border-radius: 10px;
      margin-bottom: 30px;
      height: fit-content;
      width: 100%;
    }
    .feature-img {
        justify-content: center;
        width: 100%;
    }
    .feature-img img{
        width: 100px;
    }
    .box-text{
        margin-top: 10px;
        width: 100%;
    }
    .box-text h2{
        font-size: 28px;
        margin-bottom: 15px;
    }
  }
  @media (min-width: 481px) and (max-width:1024px){
    
  }
  @media (min-width: 1024px) {
    
  }