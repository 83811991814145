.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    background-color: var(--modal-background-color);
  }
  
  .modal-content {
    text-align: center;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  // .modal-content button:hover {
  //   background-color: #1c3f60;
  // }
  