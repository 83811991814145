.fiat-withdrawal-list {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .fiat-withdrawal-list h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .withdrawals-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .withdrawals-table thead {
    color: white;
  }
  
  .withdrawals-table th,
  .withdrawals-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .withdrawals-table th {
    text-align: center;
  }
  
  .withdrawals-table tbody tr:hover {
    background-color: #494242;
  }
  
  .withdrawals-table .confirm-btn,
  .withdrawals-table .cancel-btn {
    padding: 8px 12px;
    margin: 0 5px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .confirm-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .confirm-btn:hover {
    background-color: #45a049;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #d32f2f;
  }
  
  .withdrawals-table .confirm-btn:disabled,
  .withdrawals-table .cancel-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .fiat-withdrawal-list p {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin-top: 20px;
  }
  
  .status-pending {
    color: #ff9800;
  }
  
  .status-confirmed {
    color: #4caf50;
  }
  
  .status-canceled {
    color: #f44336;
  }
  
  .status-text {
    font-weight: bold;
  }

  .status-emoji {
    font-size: 20px;
    display: inline-block;
    padding: 4px;
    text-align: center;
  }
  
  .search-container {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .search-container input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
  }
  
  