@import "../../mixins.scss";
.fadded-container{
  position: relative;
  z-index: 10;
  // background-color: rgba(0, 0, 0, .6);
  background-color: var(--modal-background-color);
  width: 100%;
  height: 100%;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // display: none;
    // background: linear-gradient(
    // 127deg,
    // hsla(0, 0%, 100%, 0.16) 17.35%,
    // hsla(0, 0%, 85%, 0.04) 76.2%
    // );
    accent-color: red;
    justify-content: center;
    align-items: center;
    @include respond(phone) {
      align-items: end;
    }
  }
  
  .modal {
    width: 50%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
    127deg,
    hsla(0, 0%, 100%, 0.16) 17.35%,
    hsla(0, 0%, 85%, 0.04) 76.2%
  );
    padding: 20px;
    border-radius: 12px;
    position: relative;
    @include respond(phone) {
      width: 100%;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }
  }

  .d-main{
    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: center;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 18px;
  }

  .modal h2{
    margin-bottom: 20px;
  }
  
  .generate-address-btn {
    padding: 0px 40px;
    background-color: transparent;
    background-image: linear-gradient(127deg, hsla(0, 0%, 100%, .16) 17.35%, hsla(0, 0%, 85%, .04) 76.2%);
    color: white;
    border: none;
    border-radius: .5rem;
    cursor: pointer;
    margin-bottom: 10px;
    border-color: rgb(56 67 80 );
    border-width: 1px;
    height: 40px;
    font-size: 15px;

  }
  
  .deposit-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .deposit-address-info-el {
    background-color: rgb(29, 35, 43);
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }

  .address-wrap{
    display: flex;
    gap: 3px;
  }
  .countdown {
    margin-bottom: 10px;
  }
  
  .deposit-btn {
    padding: 15px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .open {
    display: flex;
  }
  .warning{
    text-align: center;
  }
  .deposit-asset{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .d-coin-wrap{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .d-coin-select-wrapper{
    // display: flex;
    justify-self: center;
    width: 50%;
    position: relative;
    margin-bottom: 10px;
  }

  .d-currency-select-wrap{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
    padding: 10px;
    // width: 50%;
    // margin-bottom: 1px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.8);
  }

  .d-picker-currency{
    font-size: 20px;
  }