@import "../../mixins.scss";

.modal-fiat {
  width: 40%;
  height: max-content;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    127deg,
    hsla(0, 0%, 100%, 0.16) 17.35%,
    hsla(0, 0%, 85%, 0.04) 76.2%
  );
  padding: 20px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @include respond(phone) {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}

.fiat-deposit-wrap {
  h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.2em;
  }

  .fiat-deposit-amount {
    margin-bottom: 20px;

    label {
      font-size: 14px;
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
    }

    input {
      padding: 12px 20px;
      border-radius: 7px;
      outline: none;
      margin-bottom: 10px;
      width: 100%;
      border: 1px solid #ccc;
      background-color: transparent;
      color: #fff;
    }

    input[type="number"] {
      font-size: 16px;
    }
  }
}


.bank-peer-wrap {
  .deposit-head-text-wrap {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 4px;

    h2 {
      margin: 0;
      font-size: 1.5em;
    }
  }

  .done-transfer-btn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    border-radius: 7px;
    width: 70%;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
}


.warning-text {
    color: rgb(210, 132, 132);
    font-size: 16px;
    margin-top: 10px;
  }
  
  .bank-details-wrap {
    margin-top: 20px;
  
    .detail-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
  
      h4 {
        margin: 0;
        font-weight: bold;
        width: 150px;
      }
  
      .copy-text-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
  
        svg {
          cursor: pointer;
        }
      }
    }
  }
  