.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    // background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 95vw;  
    max-height: 95vh;
    overflow: hidden;
  }
  
  .modal-image {
    width: 700px; 
    height: 700px;
    border-radius: 8px;
    object-fit: contain;
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-close-button:hover {
    background-color: #d32f2f;
  }
  