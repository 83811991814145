@import "./mixins.scss";
*{
  margin: 0;
  padding: 0;
  border: 0px;
  box-sizing: border-box;
  :focus{
    outline: none;
  }
}

*::-webkit-scrollbar {
  display: none;
}
*::marker {
  /* display: none; */
  list-style: none;
  display: block;
  
}
:root{
  --tg-primary-color: #44a08d;
  --tg-green: #093637;
  --bg-light:#0f101e;
  --app-foreground-color: 255,255,255;
  --app-background-color: 2,8,15;
  --modal-background-color: #151f2b;
  --header-background-color: #02080f;
  --header-border-color: hsla(0,0%,90%,.1);
  --header-height: 64px;
  --light--1:linear-gradient(127deg, hsla(0, 0%, 100%, .16) 17.35%, hsla(0, 0%, 85%, .04) 76.2%);
}

body {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  // font-family: 'Roboto', sans-serif;
  // font-family: 'Inter', sans-serif;
  color: whitesmoke;
  height: 100vh;
  background-color:  #010314;
}



.app-container{
  display: flex;
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 80px); 
  scroll-behavior: smooth;
  /* background-color:  #010314; */



  background-image: url(./assets/black-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // height: 100vh;
  // width: 100%;
}

a{
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

// a:hover {
//   color: #091e32;
// }
.up-down-animation{
    animation-name: alltuchtopdown;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.error{
  color: rgb(234, 139, 139);
  font-size: 16px;
}

.warning{
  color: orange;
  font-size: 16px;
}

.delete-icon{
  color: rgb(234, 139, 139);
  font-size: 20px;
  cursor: pointer;
}

.cancel-button{
  background-color: #0f101e;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

@keyframes alltuchtopdown {
  0% {
      transform: rotateX(0deg) translateY(0)
  }

  50% {
      transform: rotateX(0deg) translateY(-30px)
  }

  to {
      transform: rotateX(0deg) translateY(0)
  }
}

@keyframes leftToRight {
    0% {
        transform: rotateX(0deg) translateX(0)
    }

    50% {
        transform: rotateX(0deg) translateX(50px)
    }

    to {
        transform: rotateX(0deg) translateX(0)
    }
}

@keyframes fade-in {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

.animate-fade-in {
  animation: fade-in .15s ease-out
}

.animate-fade-out {
  animation: fade-out .15s ease-out forwards
}

@keyframes notification-in {
  0% {
      margin-top: -30px;
      opacity: 0
  }

  70% {
      margin-top: 5px
  }

  to {
      margin-top: 0;
      opacity: 1
  }
}

.animate-notification-in {
  animation: notification-in .25s ease-in!important
}

@keyframes notification-out {
  0% {
      margin-top: 0;
      opacity: 1
  }

  to {
      margin-top: 20px;
      opacity: 0
  }
}

.animate-notification-out {
  animation: notification-out .15s ease-out!important
}

@keyframes pulse {
  50% {
      opacity: .5
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite
}

@keyframes slide-in {
  0% {
      transform: translateY(-100%)
  }

  to {
      transform: translateY(0)
  }
}


.animate-slide-in {
  animation: slide-in .3s ease-out
}

@keyframes slide-in-mobile {
  0% {
      opacity: 0;
      transform: translateY(20px)
  }

  to {
      opacity: 1;
      transform: translateY(0)
  }
}
@include respond(phone) {
  .animate-slide-in-mobile {
    animation: slide-in-mobile .2s ease-out
  }
}
// .animate-slide-in-mobile {
//   animation: slide-in-mobile .2s ease-out
// }

@keyframes slide-out-mobile {
  0% {
      opacity: 1;
      transform: translateY(0)
  }

  to {
      opacity: 0;
      transform: translateY(20px)
  }
}

.animate-slide-out-mobile {
  animation: slide-out-mobile .2s ease-out forwards
}

@keyframes spin {
  to {
      transform: rotate(1turn)
  }
}

.animate-spin {
  animation: spin 1s linear infinite
}