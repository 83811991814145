/* ***********contact-section*********** */
.contact-container{
    /* overflow-x: scroll; */
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-right: 230px; */
    position: absolute;
    top: -170px;
    z-index: 4;
    /* border-radius: 60px; */
}
.contact-wrap{
    width: 80%;
}

.contact-wrap h1{
    text-align: center;
    margin-bottom: 40px;
    font-size: 55px;
    letter-spacing: -2.6px;
    background: linear-gradient(180deg, #fff 0, #93b2c8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.contact-box{
    display: flex;
    flex-direction: column;
    background-color: var(--bg-light);
    padding: 30px;
    border-radius: 60px;
}
.contact-box h2{
    text-align: center;
    margin-bottom: 20px;
}
.input-wrap{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}
.con-input-name{
    padding: 20px 7px;
    width: 50%;
    outline: none;
    border: none;
    border-radius: 12px;
    background-color: hsla(0, 0%, 100%, .07);

}
.con-input-email{
    padding: 20px 7px;
    width: 50%;
    outline: none;
    border: none;
    color: #fff;
    border-radius: 12px;
    background-color: hsla(0, 0%, 100%, .07);

}
.con-input-message{
    padding: 10px 5px;
    width: 100%;
    height: 100px;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 12px;
    background-color: hsla(0, 0%, 100%, .07);
    margin-bottom: 20px;
    
}
.con-btn{
    display: flex;
    justify-content: center;
    align-items: center;   
}
.con-btn button{
    padding: 15px 70px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 600;
}

/* ********media-Query-contact********* */
@media (min-width: 320px) and (max-width:480px){
    .contact-container{
        display: flex;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: -300px;
        z-index: 4;
        border-radius: 20px;
    }
    .contact-wrap h1{
        text-align: center;
        margin-top: 60px;
        margin-bottom: 40px;
        font-size: 35px;
        letter-spacing: -2.6px;
        background: linear-gradient(180deg, #fff 0, #93b2c8 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .contact-box{
        display: flex;
        flex-direction: column;
        background-color: var(--bg-light);
        padding: 30px;
        border-radius: 15px;
    }
    .input-wrap{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 20px;
    }
    .con-input-name{
        padding: 10px 7px;
        width: 50%;
        outline: none;
        border: none;
        color: #fff;
        border-radius: 7px;
        background-color: hsla(0, 0%, 100%, .07);
    
    }
    .con-input-email{
        padding: 10px 7px;
        width: 50%;
        outline: none;
        border: none;
        color: #fff;
        border-radius: 7px;
        background-color: hsla(0, 0%, 100%, .07);
    
    }
    .con-input-message{
        padding: 10px 5px;
        width: 100%;
        height: 100px;
        color: #fff;
        outline: none;
        border: none;
        border-radius: 7px;
        background-color: hsla(0, 0%, 100%, .07);
        margin-bottom: 20px;
        
    }
    .con-btn button{
        padding: 10px 70px;
        border-radius: 15px;
        font-size: 16px;
        font-weight: 600;
        width: fit-content;
    }
  
}
@media (min-width: 481px) and (max-width:1024px){
  
}
@media (min-width: 1024px) {
  
}




/* **************Footer section************************************ */

.circlebig{
    position: absolute;
    top: 25%;
    animation-name: alltuchtopdown;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.circlesmall{
    position: absolute;
    right: 6%;
    top: 45%;
    animation-name: leftToRight;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear

}



.footer-main-wrap{
    position: relative;
    margin-top: 300px;
}
.footer-container{
    margin: 0px 40px;
}
.footer-wrap {
    background-size: cover;
    background-position: 50%;
    border-radius: 20px 20px 0 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 83vh;
    background: linear-gradient(180deg,#44a08d 0,#093637 47.6%,#010314 65.23%);
    border-radius: 30px;
}
.footer-text-wrap{
    position: relative;
    z-index: 3;
    top: 60%;
    color: #fff;
    width: 100%;

}
.footer-text-wrap{
    display: flex;
    padding: 30px 60px;
    
}
.footer-text-left{
    display: flex;
}
.footer-box{
    flex-grow: 1;
    margin: 0px 170px;
}
.footer-box li{
    margin-bottom: 10px;
    list-style: none;
    
}
.newletter-wrap{
    display: flex;
    align-items: center;
    background-color: hsla(0, 0%, 100%, .07);
    border-radius: 12px;
    width: 80%;
    margin-top: 20px;
}
.newletter-wrap input{
    padding: 20px 7px;
    width: 100%;
    outline: none;
    border: none;
    color: #fff;
    background-color: inherit;
    border-radius: 12px 0px 0px 12px; 
}
.send-icon{
    height: 100%;
    background-color: #fff;
    border-radius: 0px 10px 10px 0px;
    padding: 6px 10px;
    /* padding: ; */
}

hr{
    /* color: yellow; */
    /* height: 10px; */
    background: linear-gradient(225deg,#010314 0,hsla(0,0%,100%,.45) 54.98%,rgba(1,3,20,.99) 100%);
    /* background-color: yellow; */
}
.foooter-bm{
    position: relative;
    top: 62%;

}
.foooter-bm h3{
    text-align: center;
    margin-top: 20px;
}
/* **********************media-Query*********************** */
@media (min-width: 320px) and (max-width:480px){
    .footer-container{
        margin: 0px 10px;
    }
    .footer-wrap {
        background-size: cover;
        background-position: 50%;
        border-radius: 20px 20px 0 0;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg,#44a08d 0,#093637 25.6%,#010314 65.23%);
        border-radius: 30px;
    }
    .footer-box{
        flex-grow: 1;
        margin: 0px 10px;
    }
    .footer-box ul{
        color: grey;
    }
    .footer-text-wrap{
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
        
    }
    .footer-text-wrap{
        position: relative;
        z-index: 3;
        top: 30%;
        color: #fff;
        width: 100%;
    
    }
    .footer-text-left{
        display: flex;
        margin-bottom: 15px;
    }
    .footer-box p{
        color: grey;
        font-size: 16px;
    }
    .foooter-bm{
        position: relative;
        top: 38%;
    
    }
    .foooter-bm h3{
     font-size: 16px;
    }
    br{
        display: none;
      }
  }

  @media (min-width: 412px) and (max-width:915px){
    .footer-wrap {
        width: 100%;
        height: 73vh;
    }
  }
  @media (min-width: 360px) and (max-width:740px){
    .footer-wrap {
        width: 100%;
        height: 93vh;
    }
    .footer-text-wrap{
        position: relative;
        z-index: 3;
        top: 40%;
        color: #fff;
        width: 100%;
    
    }
    .foooter-bm{
        position: relative;
        top: 42%;
    
    }
  }
  @media (min-width: 430px) and (max-width:932px){
    .footer-wrap {
        width: 100%;
        height: 83vh;
    }
    .footer-text-wrap{
        position: relative;
        z-index: 3;
        top: 35%;
        color: #fff;
        width: 100%;
    
    }
    .foooter-bm{
        position: relative;
        top: 42%;
    
    }
  }
  @media (min-width: 414px) and (max-width:896px){
    .footer-wrap {
        width: 100%;
        height: 80vh;
    }
    .footer-text-wrap{
        position: relative;
        z-index: 3;
        top: 40%;
        color: #fff;
        width: 100%;
    
    }
    .foooter-bm{
        position: relative;
        top: 42%;
    
    }
  }
  @media (min-width: 481px) and (max-width:1024px){
    
  }
  @media (min-width: 1024px) {
    
  }