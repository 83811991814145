.crypto-news-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  width: 80%;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
  }
}

.crypto-news-list {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;

  .crypto-news-item {
    display: flex;
    width: 300px;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .crypto-news-thumbnail {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .crypto-news-content {
    padding: 10px 15px;
    flex-grow: 1;

    .crypto-news-title {
      font-size: 1.25rem;
      margin: 0;

      a {
        // color: #1a73e8;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .crypto-news-description {
      font-size: 1rem;
      margin: 10px 0;
      // color: #555;
    }

    .crypto-news-date {
      font-size: 0.875rem;
      // color: #999;
    }
  }
}

.loading, .error {
  text-align: center;
  margin-top: 50px;
  font-size: 1.25rem;
}

.credits-section {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;

  a {
    color: #007bff;

    &:hover {
      text-decoration: underline;
    }
  }
}