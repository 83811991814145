  .account-form-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      margin: 0 auto;
      padding: 20px;
      border-radius: 5px;

      .success-message {
        text-align: center;
        padding: 20px;
        color: #155724;
        border-radius: 5px;
      }

      .bank-form {
        display: flex;
        flex-direction: column;

        .account-form-group{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .account-label {
                margin-bottom: 5px;
                font-weight: bold;
                font-size: 18px;
              }

            .account-input {
                width: 100%;
                padding: 10px;
                border: 1px solid #ddd;
                border-radius: 4px;
                box-sizing: border-box;
              }
        }
      }
    }
    
    .account-button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
      }
      
    .message {
      margin-top: 10px;
      color: #d9534f;
    }
