.kyc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100vw;
    
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      width: 100%;
      // border: 1px solid gray;
  
      .input {
        width: 80%;
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
  
      .step {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        h3 {
          font-size: 18px;
          margin-bottom: 10px;
        }
  
        .button {
          background-color: #007bff;
          color: #fff;
          border: none;
          padding: 10px 20px;
          margin: 10px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
  
          &:hover {
            background-color: #0056b3;
          }
        }

        .video-frame {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .video {
          width: 300px;
          height: 400px;
          border-radius: 50%;
          object-fit: cover;
          border: 2px solid #3f3939;
        }
  
        .preview-image {
          border: 1px solid #ccc;
          margin: 10px;
          border-radius: 5px;
          width: 100px;
          height: 50px;
        }
  
    }
    .button-group {
      display: flex;
      justify-content: center;
      padding: 10px;
      width: 100%;
      margin-top: 20px;

      .button {
        flex: 1;
        margin: 0 10px;
      }

      .submit-button {
        background-color: #28a745;
        color: #fff;
        border: none;
        padding: 15px 30px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 18px;
      
        &:hover {
          background-color: #218838;
        }
      }
    }
}

.kyc-step-2, .pending-kyc, .approved-kyc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;

  .kyc-success-text{
    color: greenyellow;
  }

  .kyc-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #0056b3;
    }
  }
}

  .kyc-seldie-btns{
    display: flex;
  }
}
  