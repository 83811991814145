.profile-container {
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    
    .profile-avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  
    h2 {
      font-size: 24px;
      margin: 0;
    }

    .kyc-status {
        font-weight: bold;
        font-size: 20px;
    
        &.kyc-approved {
            color: green;
        }
    
        &.kyc-rejected {
            color: red;
        }
    
        &.kyc-pending {
            color: orange;
        }
    }    
  
    .edit-title {
      color: white;
      border: none;
      font-size: 20px;
      margin-left: auto;
    }
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;

    .detail-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid gray;
  
      span {
        font-weight: bold;
      }

      .image-select-input{
        display: flex;
        flex-direction: column;
        align-items: ce;
        width: 50%;
        gap: 10px;
        input {
          padding: 8px;
          border-radius: 5px;
          border: 1px solid #ccc;
          width: 100%;
          background: transparent;
          color: #fff;
          // display: none;
        }

        button{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-size: 18px;
          width: 50%;
          align-self: flex-end;
          padding: 10px;
          background-color: #007bff;
          color: #fff;
          cursor: pointer;
        }
      }

      input {
        padding: 8px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 60%;
        background: transparent;
        color: #fff;
      }
  
    }
  
    h3 {
      font-size: 18px;
    }
  
    ul {
      list-style-type: none;
      padding: 0;
      
      li {
        margin: 5px 0;
        padding: 10px;
        border-radius: 5px;
      }
    }
  
    .add-account {
      display: flex;
      flex-direction: column;
  
      input {
        padding: 8px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
  
      .add-button {
        background-color: #28a745;
        color: white;
        border: none;
        padding: 10px 16px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        align-self: flex-start;
  
        &:hover {
          background-color: #218838;
        }
      }
    }
  }

  .edit-pencil-wrap{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
  }

  .profile-account-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }