@import "../mixins.scss";

.deposit-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  h2 {
    margin-bottom: 20px;
  }

  .deposit-asset {
    margin-bottom: 20px;
    label {
      display: block;
      margin-bottom: 10px;
    }
    select {
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #ccc;
      font-size: 16px;
    }
  }

  .deposit-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    span {
      display: block;
      margin-bottom: 5px;
    }
    .copy-btn {
      padding: 10px 20px;
      background-color: #0056b3;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
    }
  }

  // .warning {
  //   color: #ff0000;
  //   font-size: 14px;
  //   text-align: center;
  // }
}
