@import  "../../mixins.scss";
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 25px;
  }
  
  .password-reset-input-group {
    width: 70%;
    padding: 10px;
    margin-bottom: 20px;
    @include respond(phone) {
      margin-bottom: 15px;
    }
  }
  
  .password-reset-input-group label {
    display: block;
  }

  .password-reset-input-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.password-reset-input-wrap{
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.password-reset-input {
    width: 50%;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    font-size:18px;
    @include respond(phone) {
      padding: 12px;
    }
  
  }

  .password-reset-eye-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 70%;
    align-self: center;
    color: black;
    font-size: 20px;
    cursor: pointer;
  }
  
  .password-reset-btn {
    padding: 15px;
    width: 50%;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    @include respond(phone) {
      padding: 7px 20px;
      font-size: 16px;
    }
  }
  
  
  .open {
    display: flex;
  }

  .w-main{
    display: flex;
    // background-color: red;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .w-coin-select-wrapper{
    // align-self: center;
    // justify-content: center;
    width: 100%;
    position: relative;
    // background: red;
  }

  .w-currency-select-wrap{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.8);
    // background: green;
  }

  .w-deposit-coin-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .w-picker-currency{
    font-size: 20px;
  }