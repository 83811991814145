.hero-wrap{
    background-image: url(../../assets/box_bg.png);
    background-size: cover;
    background-position: center;
    height: 100vh;
    width:100vw;
    position: relative;
}
.hero-wrap:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg,  #44a08d 0, #093637 47.6%, transparent 65.23%);
    z-index: 1;
    border-radius: 0 0 20px 20px;
  }
  .hero-content-wrap{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  .hero-container-wrap{
    text-align: center;
  }
.title {
    font-size: 65px;
    margin: 120px 0px 45px 0px;
    color: #fff;
    
  }
  .hero-container-wrap h3{
    font-size: 55px;
    margin-bottom: 45px;
    letter-spacing: -2.6px;
    background: linear-gradient(180deg, #fff 0,#0056b3 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .p-text{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-text p{
    font-size: 30px;
    width: 60%;
    margin-bottom: 45px;
    
  }
  .hero-btc-img{
    position: absolute;
    left: 0;
    top: 60%;
    z-index: 3;
    animation-name: alltuchtopdown;
   animation-duration: 6s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
  }
  .hero-safe-img{
    position: absolute;
    top: 60%;
    right:1%;
    animation-name: leftToRight;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
  }
  .btn-start{
    padding: 20px 70px;
    font-size: 16px;
    border-radius: 30px;
    background-color: #fff;
    border: none;
    font-weight: 700;
  }
  .btn-start:hover{
    color: #fff;
    background: #093637;
  }
/* *****************media-query***************** */
  @media (min-width: 320px) and (max-width:480px){
    .title {
      font-size: 48px;
      margin: 100px 0px 35px 0px;
    }
    .hero-container-wrap h3{
      font-size: 25px;
      margin-bottom: 15px;
      letter-spacing: -2.6px;
      background: linear-gradient(180deg, #fff 0,#0056b3 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .p-text{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .p-text p{
      font-size: 20px;
      width: 100%;
      margin-bottom: 35px;
    }
    .hero-btc-img{
      display: none;
    }
    .hero-safe-img{
      display: none;
    }
  }
  @media (min-width: 414px) and (max-width:896px){
    .title {
      font-size: 50px;
      margin: 120px 0px 45px 0px;
    }
    .hero-container-wrap h3{
      font-size: 30px;
      margin-bottom: 25px;
      margin-left: 10px;
      margin-right: 10px;
      
    }
    .p-text p{
      font-size: 20px;
      width: 100%;
      margin-bottom: 45px;
      line-height: 1.6;
      margin-left: 10px;
      margin-right: 10px;
      
    }
    
  }
  @media (min-width: 390px) and (max-width:844px){
    .title {
      font-size: 50px;
      margin: 120px 0px 45px 0px;
    }
    .hero-container-wrap h3{
      font-size: 30px;
      margin-bottom: 25px;
      margin-left: 10px;
      margin-right: 10px;
      
    }
    .p-text p{
      font-size: 20px;
      width: 100%;
      margin-bottom: 45px;
      line-height: 1.6;
      margin-left: 10px;
      margin-right: 10px;
      
    }
    
  }
  @media (min-width: 430px) and (max-width:932px){
    .title {
      font-size: 50px;
      margin: 120px 0px 45px 0px;
    }
    .hero-container-wrap h3{
      font-size: 30px;
      margin-bottom: 25px;
      margin-left: 15px;
      margin-right: 15px;
      
    }
    .p-text p{
      font-size: 22px;
      width: 100%;
      margin-bottom: 45px;
      line-height: 1.7;
      margin-left: 15px;
      margin-right: 15px;
      
    }
    
  }
  @media (min-width: 360px) and (max-width:740px){
    .title {
      font-size: 45px;
      margin: 120px 0px 45px 0px;
    }
    .hero-container-wrap h3{
      font-size: 25px;
      margin-bottom: 25px;
      margin-left: 15px;
      margin-right: 15px;
      
    }
    .p-text p{
      font-size: 22px;
      width: 100%;
      margin-bottom: 45px;
      /* line-height: 1.7; */
      margin-left: 15px;
      margin-right: 15px;
      
    }
    
  }
  @media (min-width: 412px) and (max-width:915px){
    .title {
      font-size: 45px;
      margin: 120px 0px 45px 0px;
    }
    .hero-container-wrap h3{
      font-size: 25px;
      margin-bottom: 25px;
      margin-left: 25px;
      margin-right: 25px;
      
    }
    .p-text p{
      font-size: 22px;
      width: 100%;
      margin-bottom: 45px;
      line-height: 1.7;
      margin-left: 15px;
      margin-right: 15px;
      
    }
    
  }
  @media (min-width: 481px) and (max-width:1024px){
    
  }
  @media (min-width: 1024px) {
    
  }
