@import "../../mixins.scss";

.floating-nav {
  position: fixed;
  bottom: 50px; 
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px; 
  background-color: rgba(26, 39, 54, 0.8); 
  padding: 10px 50px;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
  @include respond(phone) {
    height: 55px;
  }
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 20px;
  width: 130px;
  transition: background-color 0.3s ease, transform 0.3s ease; 

  &.active {
    // background-image: var(--light--1); 
    background: linear-gradient(127deg, #41454a 17.35%, #242a31 76.2%);
  }

}

@media (max-width: 768px) {
  .floating-nav {
    gap: 10px;
    padding: 8px 12px;
    bottom: 0px;
    width: 100%;
  }

  .nav-link {
    font-size: 14px;
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .floating-nav {
    gap: 5px;
    padding: 15px 8px;
    border-radius: 0px;
  }

  .nav-link {
    font-size: 12px;
    padding: 6px 8px;
  }
}

.float-nav-cg-menu{
  font-size: 40px;
  cursor: pointer;
}