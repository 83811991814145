.single-news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.single-news-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    gap: 50px;
}

.loading-message,
  .error-message,
  .no-news-message {
    text-align: center;
    font-size: 1.25rem;
    margin-top: 20px;
  }
  
  .news-title {
    font-size: 2rem;
  }
  
  .news-thumbnail {
    width:60%;
    height: 400px;
    border-radius: 8px;
  }
  
  .news-description {
    font-size: 25px;
    line-height: 1.6;
  }
  
  .single-news-date {
    font-size: 16px;
  }

  .single-news-link{
    font-size: 16px;

    &:hover{
        color: rgb(56, 148, 90);
    }
  }

  .single-news-bottom{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  
  .credits-section {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 16px;
  
    a {
      color: #007bff;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .credits-paragraph{
    display: flex;
    gap: 5px;
  }