@import  "../../mixins.scss";
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 25px;
  }
  
  .w-input-group {
    width: 70%;
    padding: 10px;
    margin-bottom: 20px;
    @include respond(phone) {
      margin-bottom: 15px;
    }
  }
  
  .w-input-group label {
    display: block;
  }

  .w-input-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .w-input-group .w-input {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    @include respond(phone) {
      padding: 12px;
    }
  
  }
  
  .withdraw-btn {
      padding: 10px 40px;
      border-radius: 10px;
      margin: 0 auto;
      color: #fff;
      font-size: 18px;
      background-color: rgb(23, 57, 102);
      cursor: pointer;
      @include respond(phone) {
        font-size: 14px;
      }
  }

  .password-reset-input-wrap{
    width: 100%;

    .password-reset-input{
      width: 100%;
    }
  }

  .passwd{
    position: relative;
  }
  
  .pssd-r-eye-icon{
    position: absolute;
    right: 20px;
    top: 35px;
    color: rgb(28 100 224);
    cursor: pointer;
  }

  .withdrawal-info-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    // margin-bottom: 10px;
  }

  .withdrawal-address-info-el {
    background-color: rgb(29, 35, 43);
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }
  
  
  .open {
    display: flex;
  }

  .w-main{
    display: flex;
    // background-color: red;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .w-coin-select-wrapper{
    // align-self: center;
    // justify-content: center;
    width: 100%;
    position: relative;
    // background: red;
  }

  .w-currency-select-wrap{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.8);
    // background: green;
  }

  .w-deposit-coin-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .w-picker-currency{
    font-size: 20px;
  }