.pagetest{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    P{
        font-size: 30px;
    }
}