.accordion-wrap{
    margin: 0px 200px;
}
  .accordion {
    width: 100%;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    padding: 0px 30px ;
  }
  .accordion h2{
    text-align: center;
    margin-bottom: 40px;
    font-size: 55px;
    letter-spacing: -2.6px;
    background: linear-gradient(180deg, #fff 0, #93b2c8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .item {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 30px 15px;
    padding-right: 20px;
    cursor: pointer;
    grid-template-columns: auto 1fr auto;
    background: var(--bg-light);
    align-items: center;
    margin-bottom: 15px;
    border-radius: 15px;
    
  }
  .title-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
  }
  
 
  
  .titles {
    font-size: 25px !important;
    font-weight: 500;
  }
  .title-wrap-mb{
    margin-bottom: 40px;
  }
  
  .content-box {
    grid-column: 2 / -1;
    line-height: 1.6;
    color: grey;
    font-size: 20px;
    font-weight: 700;
    
  }
  .content-box-mb{
    margin-bottom: 40px;
  }
  
  .content-box ul {
    color: #868e96;
    margin-left: 16px;
    margin-top: 16px;
  
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  /* OPEN STATE */
  .acc-open {
    background: linear-gradient(0deg,#44a08d 0,#093637 47.6%,#010314 98.23%);
    border-radius: 0px 0px 15px 15px;
  }
  
  .open .number,
  .open .title {
    color: #fff;
  }
  /* //// */


  @media (min-width: 320px) and (max-width:480px){
    .accordion {
      padding: 0px 10px ;
    }
    .accordion-wrap{
      margin: 0px 10px;
      /* width: 100%; */
   }
   .accordion h2{
    text-align: center;
    margin-bottom: 40px;
    font-size: 35px;
    letter-spacing: -2.6px;
    background: linear-gradient(180deg, #fff 0, #93b2c8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .item{
    padding: 15px 15px;
    border-radius: 10px;
  }
  .titles {
    font-size: 18px !important;
    font-weight: 600;
  }
  
  }
  