.withdrawal-details-step, .withdrawal-token-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  }
  
  .withdrawal-amount, .fw-bank-accounts, .withdrawal-token {
    margin-bottom: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .fw-account-select-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  .fw-icon-wrap{
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .withrawal-amount-input{
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    background-color: transparent;
    color: #fff;
  }
  
  .account-details {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #898484;
    border-radius: 4px;
    p {
      margin: 5px 0;
    }
  }
  
  .submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    &:disabled {
      background-color: #aaa;
      cursor: not-allowed;
    }
  }
  
  .token-info {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
  }
  
  .resend-token-btn {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    &:disabled {
      background-color: #aaa;
      cursor: not-allowed;
    }
  }