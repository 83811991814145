.spinner-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
   
}
.container {
    position: relative;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .container .spinBtn {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    color: #333;
    border: 4px solid rgba(0,0,0,0.75);
    cursor: pointer;
    user-select: none;
  }
  .container .spinBtn::before {
    content: '';
    position: absolute;
    top: -28px;
    width: 20px;
    height: 30px;
    background: #fff;
    clip-path: polygon(50% 0,15% 100%,85% 100%);
  }
  .container .wheel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #333;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 5px #333,
    0 0 0 15px #fff,
    0 0 0 18px #111;
    transition: transform 5s ease-in-out;
  }
  .container .wheel .number {
    position: absolute;
    width: 50%;
    height: 50%;
    background: var(--clr);
    transform-origin: bottom right;
    transform: rotate(calc(45deg * var(--i)));
    clip-path: polygon(0 0,56% 0,
    100% 100%,0 56%);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }
  .container .wheel .number span {
    position: relative;
    transform: rotate(45deg);
    font-size: 2em;
    font-weight: 700;
    color: #fff;
    text-shadow: 3px 5px 2px rgba(0,0,0,0.15);
  }
  .container .wheel .number span::after {
    content: 'X';
    position: absolute;
    font-size: 0.75em;
    font-weight: 500;
  }
  .result {
    margin-top: 450px;
    font-size: 2em;
    font-weight: 700;
    color: #fff;
    text-shadow: 3px 5px 2px rgba(0,0,0,0.15);
  }